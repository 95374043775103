
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({

  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '昵称'
    }
  },

  setup: (props, { emit }) => {
    const keyword = ref(props.value)
    watch(keyword, newKeyword => {
      emit('update:value', newKeyword)
    })
    watch(() => props.value, newValue => {
      keyword.value = newValue
    })
    return {
      keyword,
      doSearch: () => {
        emit('do-search')
      }
    }
  }
})
